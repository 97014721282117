import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "root",
  tabindex: "-1",
  class: "fixed inset-0 z-40 flex items-center justify-items-center",
  role: "dialog",
  "aria-labelledby": "aria-modalLabel",
  "aria-modal": "true"
}
const _hoisted_2 = { class: "\n        max-w-5xl\n        mx-auto\n        flex\n        w-full\n        h-full\n        items-center\n        justify-items-center\n      " }
const _hoisted_3 = { class: "relative w-full px-container" }
const _hoisted_4 = { class: "relative aspect-ratio-16:9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: "absolute inset-0 bg-black bg-opacity-50",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
    }),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("video", {
            class: "absolute inset-0 w-full h-full bg-amff-black",
            src: _ctx.videoSrc,
            type: "video/mp4",
            controls: "",
            autoplay: ""
          }, null, 8, ["src"]),
          _createVNode("button", {
            type: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
            class: "\n              absolute\n              top-4\n              right-4\n              lg:top-6\n              lg:right-6\n              text-amff-white\n              lg:hover:text-amff-gray\n            "
          }, [
            _createVNode(_component_CloseIcon, {
              fill: "currentColor",
              class: "w-6 h-6"
            })
          ])
        ])
      ])
    ])
  ], 512))
}