
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { onKeyStroke } from "@vueuse/core";
import { useFocusTrap } from "@vueuse/integrations";

export default defineComponent({
  components: {
    CloseIcon,
  },
  props: {
    src: { type: String, required: true },
  },
  setup(props, context) {
    // visibility
    onMounted(() => {
      document.body.style.overflow = "hidden";
    });
    onBeforeUnmount(() => {
      document.body.style.overflow = "";
    });

    // keyboard
    onKeyStroke("Escape", () => {
      context.emit("close");
    });

    // focus navigation
    const root = ref<HTMLElement | null>(null);
    useFocusTrap(root, { immediate: true });
    onMounted(() => {
      root.value?.focus();
    });

    return {
      root,
      videoSrc:
        props.src?.substr(-4) == ".mp4" ? props.src : props.src + ".mp4",
    };
  },
});
